// Main Application Container

/*------------------------------------*\
    # MAIN APPLICATION CONTAINER
\*------------------------------------*/

.l-app-content {
  // wrap `.l-app-container` and `.l-react-side-sheet` in a parent
  // with position relative so that the absolute positioned
  // Side Sheets span the same height as the app content rendered
  // within `.l-app-container`.
  position: relative;
  grid-area: content;
}

.l-app-container {
  min-height: 100vh;

  overflow: auto;

  padding: 20px;
  padding-top: 20px;
  @include transition(margin-left 0.3s, transform 0.3s);
  @include transform(translate3d(0, 0, 0));

  &.vacation-mode {
    padding-top: 80px !important;
  }
}

.l-content-wrapper {
  margin-top: $header-height-v2;
  display: grid;

  grid-template-columns: $nav-container-width-full-v2 minmax(0, 1fr);
  grid-template-areas: 'navigation content';

  @media (max-width: 1160px) {
    grid-template-columns: $nav-container-width-v2 minmax(0, 1fr);
  }
}

/*------------------------------------*\
    # React Sheet Styles
\*------------------------------------*/
.l-react-side-sheet {
  #react-sheet-provider + div {
    padding-top: $header-height-v2;
  }
}

/*------------------------------------*\
    # General Layout
\*------------------------------------*/

.step-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  margin: 40px 0px;
  clear: both;
}

.section-title {
  border-bottom: 2px solid $color-lightgrey;
  line-height: 0;

  span {
    padding-right: 40px;
    background: #fff;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    color: $color-darkgrey;
  }

  @include modifier(active) {
    span {
      color: $color-green;
    }
  }
}

.collapsible-info {
  position: relative;
  margin-top: 20px;
  border-top: 1px solid #d9d9d9;
}

.collapsible-title {
  margin-bottom: 20px;
  padding: 0px 10px 0px 0px;
  font-size: 11px;
  text-transform: uppercase;
  color: $color-green;
}

.collapsible-remove {
  position: absolute;
  top: 0px;
  right: 0px;
  color: $color-red;
  font-size: 16px;
  margin-top: -3px;
  background: #fff;
  padding-left: 10px;
  z-index: 1;

  &:hover {
    color: darken($color-red, 20%);
  }
}

.collapsible-info .box_buttons {
  right: 0;
  top: -15px;
}

.quote {
  border-left: 5px solid #eee;
  padding: 10px;
}

.inner-shadow-bottom {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 10px;
  width: 100%;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    height: 10px;
    margin-top: 10px;
    @include box-shadow(0 0 8px rgba(0, 0, 0, 0.2));
  }
}
.line {
  border: none;
  border-bottom: 1px solid #eee;
  margin-top: 40px;
  margin-bottom: 40px;
}

/*------------------------------------*\
    # PAGE TITLE
\*------------------------------------*/

.module_header {
  padding: 1em 0 3rem 0;
  margin: 0;
  font-size: 16px;
  font-weight: 600;

  b {
    font-weight: 600;
  }

  i {
    margin-right: 8px;
  }

  @include child(badge) {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid $color-grey;
    color: $color-text-secondary;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;

    @include child(icon) {
      display: inline-block;
    }

    // Modifiers
    @include modifier(draft) {
      border-color: $color-orange;
      color: $color-orange;
      cursor: pointer;
    }

    @include modifier(save) {
      border-color: $color-teal;
      color: $color-teal;
    }
  }

  + .module_header {
    &:before {
      content: '\e801';
      font-family: 'icomoon';
      padding-left: 10px;
      padding-right: 10px;
      display: inline-block;
      font-weight: normal;
    }
  }
  + .box {
    margin-top: 2rem;
  }
}

.breadcrumb {
  padding: 1em 0 2rem 0;
}

.breadcrumb .module_header {
  display: inline-block;
  padding: 0;
  font-size: 14px;
}

.module_header + .tabs {
  margin-top: -20px;
}

.module_header.module_header--actions {
  line-height: 40px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.module_content + .module_footer {
  margin-top: 20px;
}

/*------------------------------------*\
    # PAGE ACTIONS
\*------------------------------------*/

$module_action_color: darken($color-grey, 30);

.module_actions {
  margin-top: -10px;
  float: right;
}
.module_actions li {
  vertical-align: middle;
  position: relative;
  display: inline-block;
}
.module_actions li + li {
  margin-left: 0.75rem;
}
.module_actions .module_action {
  border: 1px solid $module_action_color;
  overflow: hidden;
  display: block;
  min-width: 40px;
  height: 40px;
  @include untouchable();
  // max-width: 40px;

  @include transition(max-width 0.5s ease-in-out);
  @include border-radius(5px);

  @include child(badge) {
    position: absolute;
    top: -8px;
    right: -8px;
    color: #fff;
    padding: 2px 4px;
    font-size: 10px;
    line-height: 16px;
    background: darken($color-teal, 5);
    color: #fff;

    @include border-radius(2px);
  }

  @include child(icon) {
    min-width: 40px;
    height: 40px;
    font-size: 20px;
    color: $module_action_color;
    text-align: center;
    display: block;
    position: absolute;
    left: 0;
    top: 0;

    i {
      line-height: 40px;
      margin-right: 0px;
    }
  }

  @include child(text) {
    font-size: 12px;
    text-transform: uppercase;
    white-space: nowrap;
    margin-left: 8px;
    display: block;
    line-height: 40px;
    padding: 0 20px 0 30px;
    font-weight: normal;
    letter-spacing: 0.8px;
    color: $module_action_color;
  }

  &:hover,
  &.active {
    background: $color-teal;
    border-color: $color-teal;
    cursor: pointer;
    // width: auto;
    // max-width: 200px;

    .module_action_icon,
    .module_action_text {
      color: #fff;
    }
  }
}

/*------------------------------------*\
    # BADGE
\*------------------------------------*/

.badge {
  padding: 10px;
}
.badge--version {
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 12px 10px;
  background: $color-teal;
  border: 1px solid $color-teal;
  color: #fff;
  font-size: 12px;
  text-decoration: none;

  @include border-radius(3px);

  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/*------------------------------------*\
    # BOX
\*------------------------------------*/

/*$box_padding: 32px;

.box {
    background: #fff;
    position: relative;

    @include border-radius(5px);
    @include box-shadow(0 0px 4px rgba(0, 0, 0, 0.2));

    &.box--minheight {
        min-height: 450px;
    }

    &+.box {
        margin-top: 40px;
    }

    &+.document-buttons{
        margin-top: 20px;
    }
}

*/
.box_btn {
  padding: 15px;
  text-transform: uppercase;

  color: $color-text-secondary;
  border-bottom: 1px solid #d9d9d9;
  // background:$color-lightgrey;

  i {
    margin-right: 15px;
  }
}

.box_buttons {
  position: absolute;

  top: -18px;
  right: 2rem;

  z-index: 100;
}

.box_buttons--middle {
  position: relative;
  float: right;
  top: -18px;
  right: 40px;
  z-index: 100;
}

.box_header {
  font-family: 'Gilroy-Semibold', 'Inter', Arial, 'sans-serif';
  font-weight: 500;
  color: #fff;
  padding: 15px 15px;
  color: $color-navy;

  .btn {
    margin-top: -6px;
  }
}

.box_title {
  font-family: 'Gilroy-Semibold', 'Inter', Arial, 'sans-serif';
  font-weight: 500;
  padding: (2 / 1.167) + em;
  background: #fff;
  font-size: 1.167em;
  border-radius: 8px 8px 0 0;

  i {
    margin-right: 8px;
  }

  &.box_title--large {
    font-size: 24px;
    line-height: 22px;
  }

  &.box_title--normal {
    font-size: inherit;
    font-weight: normal;
  }
}

.box_subtitle {
  padding: 10px 0px;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 8px;
  color: #777;

  i {
    margin-right: 8px;
  }

  &.box_subtitle--small {
    font-size: 10px;
  }
  &.box_subtitle--grey {
    color: $color-text-secondary;
  }
}

.box_banner {
  position: absolute;
  right: 0;
  z-index: 1; //$z-index-1;

  img {
    width: 100px;
  }
}

/*
.box_title_actions {
    position: relative;
    margin-top: 10px;

    @include child(item) {
        position:relative;
        display: inline-block;
        vertical-align: top;

        @include untouchable();

        .badge{
            position: absolute;
            right:-15px;
            top:-10px;
            font-size:10px;
            font-family:Arial;
        }

        a {
            display: inline-block;
            color: $color-font;
            text-align: center;
            margin: 0 5px;
            width: 90px;
            height: 75px;
            padding: 6px;
            &:hover {
                cursor: pointer;
                padding: 5px;
                color: $color-teal;
                //        border: 1px solid lighten($color-border, 10);
                @include border-radius(2px);
                a {
                    color: $color-teal;
                }
            }
            i {
                position:relative;
                margin-top: 10px;
                font-size: 30px;
                display: inline-block;
            }
            p {
                margin-bottom: 0px;
                margin-top: 10px;
                font-size: 10px;
                line-height:1;
            }
        }
    }
}

.box_content {
    background: #fff;
    padding: $box_padding;

    position: relative;
    min-height: 90px;

    &.box_content--loading:after {
        content: " ";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        background: #fff no-repeat center;
        background: rgba(255, 255, 255, 0.6) no-repeat center;
        background-image: url(../public/images/_base/loaders/rect_loader.gif);
    }

    &.box_content--nopadding {
        padding: 0 !important;
    }

    &.box_content--compact{
        padding: 16px !important;
    }

    &.box_content--nominheight{
        min-height: 0;
    }

}

.box_title + .box_content {
    padding-top: $box_padding/2;
}

.box_content + .box_content{
    padding-top:0;
}*/

/*------------------------------------*\
    # COMPACT BOXES
\*------------------------------------*/

/*.box.box--uploader {
    position: relative;
    border: 2px dashed #A9A9A9;
    box-shadow: none;
    border-style: dashed;
}*/

/*------------------------------------*\
    # COMPACT BOXES
\*------------------------------------*/

/*.box.box--compact {
    .box_title {
        font-size: 14px;
        padding: $box_padding/2;

        &.box_title--large{
            font-size: 24px;
            padding: $box_padding;
        }
    }

    .box_content {
        padding: $box_padding/2;
    }

    .box_footer{
        padding: 0 $box_padding/2 $box_padding/2 $box_padding/2;
    }

    .box_title + .box_content {
        padding-top: $box_padding/4;
    }

    .box_buttons{
        right: $box_padding/2;
    }
}

.box_empty {
    color: #999;
    text-align: center;
    text-transform: uppercase;

    padding: 20px 0;

    .fa {
        color: #ddd;
        margin-bottom: 5px;
    }
}

.box_subfooter {
    background: #F9F9F9;
    position: relative;
    padding: 20px 20px 20px 54px;

    @include child(ribbon) {
        position: absolute;
        left: 20px;
        width: 28px;
        top: -10px;
    }

    p {
        color: darken($color-grey, 20);
        margin-bottom: 0px;
        margin-left: 20px;
        line-height: 16px;
    }
}

.box_footer {
    padding:$box_padding;
    padding-top: 5px;
    // background: #eee;
    // border: 1px solid #ddd;
    @include clearfix;
}*/

/*------------------------------------*\
    # Cards
\*------------------------------------*/

.card {
  background: #fff;

  padding: 15px;

  -webkit-box-shadow: 0 0 10px #ddd;
  box-shadow: 0 0 10px #ddd;

  &.card--grey {
    background: #eee;
    border: 1px solid #ddd;
  }

  &.card--nopadding {
    padding: 0;

    .card_content {
      padding: 15px;
    }
  }
}

.card + .card {
  margin-top: 20px;
}

.card_title {
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
}

.card_content {
}

.card_banner {
  padding: 20px 40px;
  background: url(../../../../public/images/onboarding/green_bg.jpg) no-repeat;
  background-size: cover;

  color: #fff;
  text-align: center;

  h2 {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 300;
  }

  h3 {
    font-size: 14px;
    font-weight: 300;
  }
}

.card_footer {
  background: #eee;
  position: relative;
  padding: 15px;
}

/*------------------------------------*\
    #Page Gallery
\*------------------------------------*/
.img_gallery {
  .img_gallery_item {
    display: inline-block;
    margin-right: 10px;
  }
  .img_gallery_thumb {
    width: 60px;
    height: 60px;
    border: 3px solid #ccc;
    background: #f9f9f9 center no-repeat;
    overflow: hidden;
    text-align: center;
    position: relative;
    display: inline-block;

    &.done {
      cursor: pointer;
      background-image: none;
    }

    img {
      position: relative;
    }
  }
}

/*------------------------------------*\
    #Modal Overrides
\*------------------------------------*/

.modal-content {
  border: none;

  > .box {
    border-radius: 8px 8px 0 0;
    @include box-shadow(none);
  }
}

/*------------------------------------*\
    # Split Layout
\*------------------------------------*/

.split-layout {
  padding-left: 300px;
}

/*------------------------------------*\
    # FILTER-TOOLS
\*------------------------------------*/

.filters + .filter-tools {
  margin-top: 2em;
}

.filter-tools {
  border: 1px solid $color-teal;
  background: #f9f9f9;
  padding: 15px 15px 0 0;

  .input-label {
    margin-bottom: 0;
  }

  &.filter-tools--standalone {
    border: 1px solid darken(#f9f9f9, 5);
  }

  &.filter-tools--nostyle {
    border: none;
    background: none;
  }
}

.filter-tools {
  .filter-tool:first-child {
    margin-left: 15px;
  }
}

.filter-tool {
  display: inline-block;
  padding-bottom: 15px;
}

.filter-tool + .filter-tool {
  margin-left: 15px;
}

.filter-row {
  position: relative;
  padding: 15px 0;
  @include pie-clearfix();

  &.filter-row--standalone {
    background: #fff;
    padding: 15px 15px 15px 15px;
    border: 1px solid #ccc;
    // border-bottom:none;
  }

  .btn {
    height: 36px;
    line-height: 24px;
  }
}

.filter-cell {
  padding: 15px;
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.filter-cell + .filter-cell {
  // border-left:1px solid #ccc;
}

/*------------------------------------*\
    # APPROVAL CONTAINER COST TABLE
\*------------------------------------*/

.approval-container {
  .total-container {
    margin: 10px auto;
    width: 80%;

    td {
      font-size: 12px;
      padding: 5px 10px;
      width: 50%;

      text-align: left;
    }

    tr td:first-child {
      text-align: right;
    }

    .row-total {
      td {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }
}

/*------------------------------------*\
    # FIELDSET SEPERATOR
\*------------------------------------*/

.horizontal-line-seperator {
  text-align: center;

  border-top: 1px solid $color-border;

  span {
    display: inline-block;
    position: relative;
    top: -8px;
    margin-top: -10px;

    background: white;
    padding: 0 10px;

    font-size: 11px;
    color: $color-border;
  }
}

/*------------------------------------*\
    # BOX BANNER
\*------------------------------------*/

.box {
  .box_banner__pattern {
    height: 130px;
    min-height: 100px;
    padding: 15px 32px;
    background: #F9FAFB;
    position: relative;
    z-index: 1;

    .vacation-badge {
      background: #faab18;
      border-radius: 100%;
      padding: 5px;
      &.icon-timer:before {
        color: #fff;
        font-size: 30px;
      }
    }
    // icon-timer modifications for delegated approvers
    .icon-timer {
      border-radius: 100%;
      background: #faab18;
      padding: 5px;
      color: white;
      font-size: 20px;
      position: absolute;
      left: 135px;
      top: 110px;
    }
  }
}
